.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2em 2em 2em 4em;
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.products-bar {
  width: 30vw;
  padding: 0em 5em 0em 5em;
  background-color: rgba(101, 72, 97, 0.136);
  border-radius: 0.5rem;
  color: rgba(244, 242, 244, 0.764);
}
.account {
  padding-right: 4rem;
}
.product-item {
  font-size: 1.125rem;
}

img {
  height: 3.2rem;
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}
.logo {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 450;
  color: #b534ae;
}

.nav-button {
  border-radius: 0.5rem;
  width: 7vw;
  padding: 0.9rem;
  font-size: 1rem;
  color: #f4f2f4d1;
  background-color: rgba(40, 31, 38, 0.679);
  border: 2px solid #fafaf4f0;
}
.nav-button:hover {
  color: #f4f2f4d1;
  background-color: rgba(240, 125, 221, 0.878);
  border: 2px solid #fafaf4f0;
}
/* .login-button {
  background-color: #737107b8;
} */

.router-link {
  color: #f4f2f4d1;
}
a {
  text-decoration: none;
}
