.testimonials-section {
  padding: 5rem 8rem 10rem 8rem;
  background-color: #f5d37e;
}
.testimonials-section-list {
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
  justify-content: space-around;
  align-items: center;
  height: 15vw;
  width: calc(200%); /* Adjusted for doubled content */

  animation: scroll-horizontal 20s linear infinite;
  animation-delay: 10s;
  position: relative;
}
@keyframes scroll-horizontal {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
.testimonials-section-item {
  border: 10px solid #e4d7b47f;
  border-radius: 1.125rem;
  background: #e4d7b47f;
  height: 100%;
  padding: 2em;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonials-section-title {
  color: #1d16027f;
  font-size: 2.5rem;
  font-weight: 500;
}
.testimonials-item-header {
  font-size: 1.25rem;
  font-weight: 400;
  color: rgb(117, 70, 117);
}
.product-item-content {
  padding-top: 3rem;
}
