.hero {
  /* background: linear-gradient(180deg, #ffc58d 0%, #b131aa 100%); */
  background-color: #000000 !important;
}
.main-section {
  padding-top: 7rem;
  display: flex;
  gap: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
h1 {
  line-height: 1.1;
  padding: 0px;
  margin: 1.125rem;
  font-size: 4.25rem;
  font-weight: 500;
  color: rgb(222, 191, 222);
}
.main-heading-highlight {
  color: rgb(158, 81, 158);
}
h3 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1.125rem;
  width: 50vw;
  padding: 0;
  color: rgba(251, 248, 250, 0.512);
}
.hero-button {
  font-size: 1.5rem;
  margin-top: 7rem;
  padding: 1.5rem;
  color: rgb(0, 0, 0);
  background-color: #ead5e5ba;
  border-radius: 50%;
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
.main-scroll {
  color: #ead5e5ba;
}
.main-scroll:hover {
  color: #ead5e5ba;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
