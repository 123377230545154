.newsletter {
  /* background: linear-gradient(180deg, #ffc58d 0%, #b131aa 100%); */
  background-color: #000000 !important;
}
.newsletter-main-section {
  padding: 7rem 0rem 14rem 0rem;
  display: flex;
  gap: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.newsletter-main-banner {
  width: 70vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newsletter-main-heading-highlight {
  color: rgb(211, 162, 14);
}
.newsletter-tagline {
  text-align: center;
}
#newsletter-subscription {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem;
  width: 40vw;
  height: 8vh;
  background-color: #000000;
  border-radius: 1rem;
  border: 2px solid white;
  font-size: 1rem;
}
#newsletter-email-input {
  padding-left: 2rem;
  width: 20vw;
  color: white;
  background-color: #000000;
  border: none;
  outline: none;
}
::placeholder {
  color: white;
  font-size: 1rem;
}
#newsletter-subscribe-button {
  border-radius: 1rem;
  padding: 1rem;
  width: 10vw;
  height: 8vh;
  border: none;
  background-color: rgb(211, 162, 14);
  border: 1px solid white;
}
#newsletter-subscribe-button:hover {
  transition: 1s;
  background-color: white;
  color: rgb(211, 162, 14);
  border: 2px solid white;
}
