.products-section {
  padding: 0.1rem 8rem 10rem 8rem;
  background-color: #000000;
}
.products-section-list {
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
  justify-content: space-around;
  align-items: center;
  height: 15vw;
}
.product-section-item {
  border: 2px solid #31012c;
  border-radius: 1.125rem;
  background: #edd9ee;
  height: 100%;
  padding: 2em;
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products-section-title {
  color: #fadafbd1;
  font-size: 2.5rem;
  font-weight: 500;
}
.product-item-header {
  font-size: 1.25rem;
  font-weight: 400;
  color: rgb(117, 70, 117);
}
.product-item-content {
  padding-top: 3rem;
}
.hover-content {
  display: none;
}
.product-section-item:hover {
  background: black !important;
  background-color: #170010 !important;
  transform: scale(1.125);
  transition-timing-function: ease-in-out;
  transition-duration: 0.75s;
}
.product-section-item:hover .hover-content {
  display: block;
  color: white;
}
.product-section-item:hover .product-item-content {
  color: rgba(246, 221, 239, 0.54) !important;
}
