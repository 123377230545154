.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.login-box {
  width: 30vw;
  padding: 2rem;
  border-radius: 1.125rem;
  background-color: rgba(252, 194, 5, 0.51);
}
.login-header-text {
  display: flex;
  justify-content: center;
}
.login-wrapper {
  background-color: #e4d7b47f !important;
}
.form-box {
  width: 380px;
  height: 450px;
  background-color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0.5;
  border-radius: 2%;
}
.header-form {
  margin-bottom: 15px;
}
.login-button {
}
.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
